import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from "@/components/Pagination";
import { fetchAlarmContactList, addAlarmContact, delAlarmContact, updateAlarmContact, fetchAlarmDeviceList } from "../../../api/charge"; // import {} from 'iview-area'

export default {
  name: "AlarmContact",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: "",
        is_enable: 0,
        alarm_id: ""
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      submitLoading: false,
      selectCount: 0,
      selectList: [],
      villageDialog: false,
      dialogForm: {
        id: "",
        name: "",
        alarm_id: "",
        mobile: "",
        is_enable: true
      },
      textMap: {
        update: "编辑联系人",
        create: "添加联系人"
      },
      dialogTitle: "",
      deviceLoading: false,
      deviceOptions: [],
      btnLoading: false,
      rules: {
        name: [{
          required: true,
          message: "姓名不能为空"
        }],
        mobile: [{
          required: true,
          message: "手机不能为空",
          trigger: "blur"
        }],
        alarm_id: [{
          required: true,
          message: "所属设备不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.searchForm.alarm_id = this.$route.query ? this.$route.query.alarm_id : '';
    this.remoteAlarmDevice(this.searchForm.alarm_id);
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchAlarmContactList(data).then(function (response) {
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      var data = Object.assign({}, this.dialogForm);
      this.btnLoading = true;
      addAlarmContact(data).then(function (res) {
        _this2.$message({
          type: "success",
          message: "添加设备成功"
        });

        _this2.villageDialog = false;
        _this2.btnLoading = false;

        _this2.getTableData();
      }).catch(function (err) {
        _this2.btnLoading = false;

        _this2.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleEdit: function handleEdit(row) {
      var data = Object.assign({}, row);
      this.deviceOptions.push(data.alarm);
      this.dialogTitle = "update";
      this.villageDialog = true;
      this.dialogForm = {
        id: data.id,
        name: data.name,
        alarm_id: data.alarm_id,
        mobile: data.mobile,
        is_enable: data.is_enable == 1 ? true : false
      };
    },
    handleEditBtn: function handleEditBtn() {
      var _this3 = this;

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          _this3.btnLoading = true;
          var data = Object.assign({}, _this3.dialogForm);
          updateAlarmContact(data).then(function (res) {
            // 成功之后刷新或
            // this.dataList.unshift('返回的数据')
            _this3.$message({
              type: "success",
              message: "编辑设备成功"
            });

            _this3.villageDialog = false;
            _this3.btnLoading = false;

            _this3.getTableData();
          }).catch(function (err) {
            _this3.btnLoading = false;

            _this3.$message({
              type: "error",
              message: err.data["message"]
            });
          });
        }
      });
    },
    resetContact: function resetContact() {
      this.dialogForm = {
        id: "",
        name: "",
        alarm_id: "",
        mobile: "",
        is_enable: true
      };
    },
    handleAdd: function handleAdd() {
      var _this4 = this;

      this.resetContact();
      this.villageDialog = true;
      this.dialogTitle = "create";
      this.dialogForm.alarm_id = this.$route.query ? this.$route.query.alarm_id : '';
      this.remoteAlarmDevice(this.dialogForm.alarm_id);
      this.$nextTick(function () {
        _this4.$refs["dialogForm"].clearValidate();
      });
    },
    handleDel: function handleDel(row) {
      var _this5 = this;

      console.log("删除设备");
      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u8BE5\u8BBE\u5907 ".concat(row.name, "?");
      var title = "删除";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var data = row.id;
        delAlarmContact(data).then(function (res) {
          console.log(res);

          _this5.$message({
            type: "success",
            message: "删除设备成功"
          });

          var index = _this5.dataList.indexOf(row);

          _this5.dataList.splice(index, 1);
        }).catch(function (err) {
          _this5.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    //搜索用户
    remoteAlarmDevice: function remoteAlarmDevice(query) {
      var _this6 = this;

      if (query !== "") {
        this.deviceLoading = true;
        setTimeout(function () {
          _this6.deviceLoading = false;
          var data = {
            keyword: query
          };
          fetchAlarmDeviceList(data).then(function (res) {
            _this6.deviceOptions = res.data;
          });
        }, 100);
      } else {
        this.memberOptions = [];
      }
    }
  }
};